import AOS from "aos";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.css";

AOS.init({
  duration: 2000,
});

let menuexpand = false;
menu_btn.addEventListener("click", (e) => {
  if (menuexpand) {
    menu.classList.add("hidden");
    menu.classList.remove("block");
    e.target.classList.remove("fa-xmark");
    e.target.classList.add("fa-bars");
    menuexpand = false;
  } else {
    menu.classList.remove("hidden");
    menu.classList.add("block");
    e.target.classList.add("fa-xmark");
    e.target.classList.remove("fa-bars");
    menuexpand = true;
  }
});

//code for routing
function loadContent(page) {
  const contentDiv = document.getElementById("content");
  if (page == "about") {
    fetch("./pages/about.html")
      .then((response) => response.text())
      .then((data) => {
        contentDiv.innerHTML = data;
      });
  }
  else if(page == "admin")
  {
    fetch("./admin/login.html")
    .then((response) => response.text())
    .then((data) => {
      document.body.innerHTML = data;
      const loader = document.getElementById('loader')
      async function handleLogin(event) {
        event.preventDefault(); // Prevent the default form submission

        loader.classList.remove('hidden');
        loader.classList.add('flex');
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
    
        // console.log('Username:', username);
        // console.log('Password:', password);
    
        try {
            const response = await fetch('checkinputs.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
    
            console.log('Response status:', response.status);
            const data = await response.json(); // Assuming the server returns JSON
            
            if (response.ok) {
              window.location.href = data.redirect; // Replace with your desired path
            }
            else {
              document.getElementById('login-error-message').classList.remove('hidden');
              document.getElementById('login-error-message').classList.add('block');
              document.getElementById('login-error-message').textContent = data.message || 'Login failed. Please try again.';
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
        finally{
          loader.classList.remove('flex');
          loader.classList.add('hidden');
        }
    }

      document.getElementById("login-button").addEventListener("click", (event) => {
        event.preventDefault();
         handleLogin(event);
      })
    });
  }
  else if(page == "diseases/maleinfertility/azoospermia")
    {
      fetch("./diseases/azoospermia.html")
      .then((response) => response.text())
      .then((data) => {
        contentDiv.innerHTML = data;
        operateAppointmentWindow();
        document.documentElement.scrollTop = 0;
      });
    }
    else if(page == "maleinfertility")
    {
      fetch("./diseases/maleinfertility.html")
      .then((response) => response.text())
      .then((data) => {
        contentDiv.innerHTML = data;
        document.documentElement.scrollTop = 0;
      });
    }
    else if(page == "femaleinfertility")
      {
        fetch("./diseases/female-infertility.html")
        .then((response) => response.text())
        .then((data) => {
          contentDiv.innerHTML = data;
          document.documentElement.scrollTop = 0;
        });
      }
      else if(page == "hydrocele")
        {
          fetch("./diseases/hydrocele.html")
          .then((response) => response.text())
          .then((data) => {
            contentDiv.innerHTML = data;
            document.documentElement.scrollTop = 0;
          });
        }
        else if(page == "skindisorders")
          {
            fetch("./diseases/skin-disorders.html")
            .then((response) => response.text())
            .then((data) => {
              contentDiv.innerHTML = data;
              document.documentElement.scrollTop = 0;
            });
          }
  else {
    fetch("./pages/main.html")
      .then((response) => response.text())
      .then((data) => {
        contentDiv.innerHTML = data;
        startCounter(document.getElementById("target"), patientCounter, 10000);
        startCounter(document.getElementById("target"), expCounter, 25);
        startScrolling();
        operateAppointmentWindow();
        document.addEventListener("scroll", showGoToTop);
      });
  }
}

//code for resetting menu
function resetMenu() {
  menu.classList.add("hidden");
  menu.classList.remove("block");
  menu_btn.classList.remove("fa-xmark");
  menu_btn.classList.add("fa-bars");
  menuexpand = false;
}

//code for navigation
const navs = document.querySelectorAll(".navigation");

navs.forEach((nav) => {
  nav.addEventListener("click", (e) => {
    e.preventDefault();
    const hash = e.target.textContent;
    switch (hash) {
      case "Home":
        window.location.href = "#";
        resetMenu();
        break;
      case "About Us":
        window.location.href = "#about";
        resetMenu();
        break;
      default:
        break;
    }
  });
});

function router() {
  const hash = window.location.hash.substring(1) || "/";
  loadContent(hash === "/" ? "" : hash);

  switch (hash) {
    case "/":
      document.title = "Mediveda-Home"; // Set to your desired title for home
      break;
    case "about":
      document.title = "Mediveda-About Us"; // Set to your desired title for about
      break;
    case "contact":
      document.title = "Mediveda-Contact Us"; // Set to your desired title for contact
      break;
    case "admin":
      document.title = "Mediveda-Admin Login"; // Set to your desired title for contact
      break;
    case "diseases/maleinfertility/azoospermia":
      document.title = "Azoospermia"; // Set to your desired title for contact
      break;
    // Add more cases as needed
    default:
      document.title = "Page Not Found"; // Fallback title for unmatched routes
      break;
  }

  navs.forEach((nav) => {
    if (nav.dataset.page === hash) {
      nav.classList.add("text-[red]");
    } else {
      nav.classList.remove("text-[red]");
    }
  });
}

window.addEventListener("hashchange", router);
window.addEventListener("load", router);

function patientCounter(maxNumber) {
  let currentCount = 0;
  const counterElement = document.getElementById("p-counter");
  const interval = setInterval(() => {
    if (currentCount < maxNumber) {
      currentCount += 100;
      counterElement.innerText = currentCount;
    } else {
      clearInterval(interval);
    }
  }, 50); // Adjust the delay (in milliseconds) as needed
}

function expCounter(maxNumber) {
  let currentCount = 0;
  const counterElement = document.getElementById("c-counter");
  const interval = setInterval(() => {
    if (currentCount < maxNumber) {
      currentCount++;
      counterElement.innerText = currentCount;
    } else {
      clearInterval(interval);
    }
  }, 100); // Adjust the delay (in milliseconds) as needed
}

function startCounter(targetElement, counterFunction, maxNumber) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        counterFunction(maxNumber); // Change this number as needed
        observer.unobserve(entry.target); // Stop observing once it has counted
      }
    });
  });

  observer.observe(targetElement);
}

function startScrolling() {
  let startX,
    endX,
    t_width = 0;
  let slides = document.querySelectorAll(".slide");
  let i = 0;
  const slider = document.querySelector("#slider");
  slider.addEventListener("touchstart", (e) => {
    startX = e.touches[0].clientX;
  });


  slider.addEventListener("touchend", (e) => {
    endX = e.changedTouches[0].clientX;
    // console.log( `this is endx ${endX}`)
    let difference = startX - endX;
    // console.log(difference)
    if (difference > 0) {
      if (i < 2) {
        t_width = t_width + 90;
        document.getElementsByClassName("slide")[
          i
        ].style.transform = `translateX(-${t_width}vw)`;
        document.getElementsByClassName("slide")[
          i + 1
        ].style.transform = `translateX(-${t_width}vw)`;
        if (i < 2) {
          i++;
        }
      }
    } else {
      if(i>0)
      {
        t_width = t_width - 90;
        document.getElementsByClassName("slide")[
          i
        ].style.transform = `translateX(-${t_width}vw)`;
        document.getElementsByClassName("slide")[
          i - 1
        ].style.transform = `translateX(-${t_width}vw)`;
        
        if(i>0)
        {
            i--;
        }
      }
      
    }
  });
}

const disease_menu_overlay = document.getElementById("disease_menu_overlay");
const disease_menu_item = document.getElementById("disease_menu_item");
const disease_menu = document.getElementById("disease_menu");

document.getElementById("copyright-content").innerHTML ="Copyright "+ new Date().getFullYear() + " © Mediveda. All Rights Reserved.";

disease_menu.addEventListener("mouseenter", (e) => {
  disease_menu_overlay.classList.remove("hidden");
  disease_menu_overlay.classList.add("block");
});

disease_menu.addEventListener('mouseleave', () => {
  setTimeout(() => {
    if (!disease_menu_overlay.matches(':hover')) {
      disease_menu_overlay.classList.add("hidden");
    }
  }, 200);
});

disease_menu_overlay.addEventListener("mouseleave", (e) => {
  disease_menu_overlay.classList.remove("block");
  disease_menu_overlay.classList.add("hidden");
});


function operateAppointmentWindow()
{
  let appointment_window = document.getElementById("appointment-window")
  document.getElementById("appointment-btn").addEventListener("click",()=>{
    appointment_window.classList.add("flex")
    appointment_window.classList.remove("hidden")
  })

  document.getElementById("appointment-btn2").addEventListener("click",()=>{
    appointment_window.classList.add("flex")
    appointment_window.classList.remove("hidden")
  })

  document.querySelector("#apmnt-window-closer").addEventListener("click",()=>{
    appointment_window.classList.add("hidden")
      appointment_window.classList.remove("flex")
  })

  window.addEventListener("click",(e)=>{
    if(e.target == appointment_window)
    {
      appointment_window.classList.add("hidden")
      appointment_window.classList.remove("flex")
    }
  })  
  
}

function showGoToTop()
{
  if(window.scrollY > 400)
  {
    document.getElementById("go-to-top").classList.remove("hidden")
    document.getElementById("go-to-top").classList.add("flex")
  }
  else
  {
    document.getElementById("go-to-top").classList.remove("flex")
    document.getElementById("go-to-top").classList.add("hidden")
  }
}

const disease_btns = Array.from(document.getElementsByClassName("disease"));

disease_btns.forEach((btn) => {
  btn.addEventListener("click", () => {
    disease_menu_overlay.classList.add("hidden")
  });
});

 

